body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  transition: background-color 0.3s ease;
}

.loading-screen.light {
  background-color: #F8F8F9;
  color: #161617;
}

.loading-screen.dark {
  background-color: #161617;
  color: #FFFFFF;
}

.loading-memoji {
  width: 150px;
  height: 150px;
  object-fit: contain;
}

.loading-role {
  font-size: 1.2rem;
  margin-top: 10px;
}

.flip-card {
  background-color: transparent;
  width: 200px;
  height: 200px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d; /* Safari/Chrome */
}

.flip-card.flipped .flip-card-inner {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg); /* Safari/Chrome */
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden; /* Safari/Chrome */
  display: flex;
  justify-content: center;
  align-items: center;
}

.flip-card-front {
  background-color: transparent;
}

.flip-card-back {
  transform: rotateY(180deg);
}
