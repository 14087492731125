/* Ensure the html and body take up full height and have a consistent background */
html, body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background-color: #F8F8F9; /* Default to light theme background */
  color: #161617;
  transition: all 0.3s ease;
  font-family: 'Arial', sans-serif;
}

.hidden {
  display: none; /* Hide content completely during the loading phase */
}


.content-wrap {
  max-width: 800px;
  width: 90%;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Ensure left alignment */
}

.main-content {
  width: 100%;
  text-align: left; /* Ensure left alignment */
}

.profile-image {
  width: 150px;
  height: 150px;
  cursor: pointer;
  margin-bottom: 20px;
  align-self: flex-start; /* Ensure left alignment */
}

h1 {
  font-size: 2.5rem;
  margin: 0;
  padding: 0;
  line-height: 1.5;
}

.h1-container {
  margin-bottom: 20px;
}

p {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 15px;
}

a {
  color: inherit;
  text-decoration: none;
  border-bottom: 1px solid currentColor;
}

/* Light theme */
body.light {
  background-color: #F8F8F9;
  color: #161617;
}

/* Dark theme */
body.dark {
  background-color: #161617;
  color: #FFFFFF;
}

.social-links {
  margin-top: 20px;
}

.social-links a {
  font-size: 1.5rem;
  margin: 0 10px 0 0;
  color: inherit;
  border-bottom: none;
}

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  transition: background-color 0.3s ease;
}

.loading-screen.light {
  background-color: #F8F8F9;
  color: #161617;
}

.loading-screen.dark {
  background-color: #161617;
  color: #FFFFFF;
}

.loading-memoji {
  width: 150px;
  height: 150px;
  object-fit: contain;
}

/* Mobile view (max-width: 600px) */
@media (max-width: 600px) {
  .content-wrap {
    align-items: flex-start; /* Align content to the left on mobile */
  }

  .main-content {
    text-align: left; /* Ensure text remains left-aligned */
  }

  .profile-image {
    align-self: flex-start; /* Keep image aligned to the left */
  }

  h1 {
    font-size: 2rem;
  }

  p {
    font-size: 1rem;
  }
}
